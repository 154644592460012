import React from 'react';
import { navigate } from 'gatsby-link';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Title from '../components/Title';

import '../css/contact.scss';

function encode(data) {
  return Object.keys(data)
    .map(
      key =>
        `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
    )
    .join('&');
}

export default function Contact() {
  const [state, setState] = React.useState({});

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch(error => alert(error));
  };

  return (
    <Layout>
      <SEO
        title="Contact | Alex Policastro"
        description="Contact me today"
        url="https:///www.alexpolicastro.com/contact"
      />

      <section
        className="contact-page"
        data-sal="fade"
        data-sal-duration="2000"
        data-sal-delay="100"
        data-sal-easing="easeInOutSine"
        role="main"
      >
        <Title title="contact" subTitle="let's talk" h1 />
        <article className="contact-form">
          <form
            name="contact"
            method="post"
            action="/thanks/"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={handleSubmit}
          >
            {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
            <input type="hidden" name="form-name" value="contact" />
            <p hidden>
              <label>Don’t fill this out</label>
              <input name="bot-field" onChange={handleChange} />
            </p>
            <div className="form-group">
              <label id="yourName">
                Your name
                <br />
              </label>
              <input
                type="text"
                name="name"
                onChange={handleChange}
                className="form-control"
                aria-labelledby="yourName"
              />

              <label id="yourEmail">
                Your email
                <br />
              </label>
              <input
                type="email"
                name="email"
                onChange={handleChange}
                className="form-control"
                aria-labelledby="yourEmail"
              />
              <label id="message">
                Message
                <br />
              </label>
              <textarea
                name="message"
                onChange={handleChange}
                rows="10"
                cols="50"
                className="form-control"
                aria-labelledby="message"
              />
            </div>
            <button type="submit" className="submit-btn btn">
              Send
            </button>
          </form>
        </article>
      </section>
    </Layout>
  );
}
